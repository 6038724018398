export const firebaseConfig = {
  apiKey: "AIzaSyAPNX-TreTgJKerC2UDyiZop6ZtPg8l01U",
  authDomain: "stanfordch.firebaseapp.com",
  projectId: "stanfordch",
  storageBucket: "stanfordch.appspot.com",
  messagingSenderId: "566867269910",
  appId: "1:566867269910:web:679b73a4122324ff4f5837"
};

export const vapidKey = `BGIsNln1F0jKFRRnoWdEqeFgaoJE6RdCd6SLUUW6DGvWlgKG0VNyqqxzmrYj-bo1rsF3l2iUZi9hfjIhg433m_A`;

// ...

const region = 'us-central1';

export function getCloudFunctionUrl(cfName) {
  if (process.env.REACT_APP_TOKEN_EMULATOR === 'true') {
    return `http://localhost:5001/${firebaseConfig.projectId}/${region}/${cfName}`;
  } 

  return `https://${region}-${firebaseConfig.projectId}.cloudfunctions.net/${cfName}`;
}